import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function ScrolltoTopPages() {
  const location = useLocation();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    // Check if the page was reloaded (refresh)
    const navigationType = performance.getEntriesByType("navigation")[0]?.type;

    if (navigationType === "reload" && isFirstLoad) {
      setIsFirstLoad(false); // Skip the scroll-to-top on the first load
      return; // Don't execute scroll-to-top on page reload
    }

    // Scroll to top on route change
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [location]);

  return null;
}

export default ScrolltoTopPages;
