import axios from "axios";
import login from "./authService";

class ApiRequest {
  constructor() {
    this.endpoint = process.env.REACT_APP_API_URL;
    // this.token = localStorage.getItem("token");
    this.token = process.env.REACT_APP_TOKEN;
    // To Do - need to remove this - hotfix - temp solution
    localStorage.setItem(
      "token",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2YzZjZDFlNTBmNTg3MTllYjQ2MDIyZSIsImlhdCI6MTczODY2ODYzNH0.ygLoKaGZIuFD2EwilOczVT3-6P5lR57wy_AQhFznP6w"
    );
  }

  async sendRequest(json, files = []) {
    console.log(this.token, json);
    if (!this.token) {
      await this.fetchToken();
    }

    const data = new FormData();
    data.append("request", JSON.stringify(json));

    if (Array.isArray(files)) {
      files.forEach((file) => {
        data.append("files", file);
      });
    } else if (files) {
      data.append("files", files);
    }

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: this.endpoint,
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      const resp = response.data;

      if (resp.data[0].status === 401 || resp.data[0].status === 403) {
        localStorage.clear();
        sessionStorage.clear();
        return;
      } else {
        return resp;
      }
    } catch (error) {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        const newToken = await login();
        if (newToken) {
          localStorage.setItem("token", newToken);
          this.token = newToken;

          config.headers["Authorization"] = `Bearer ${newToken}`;

          try {
            const retryResponse = await axios.request(config);

            return retryResponse.data;
          } catch (error) {
            console.error("Something went wrong!");
            localStorage.clear();
            sessionStorage.clear();
          }
        } else {
          console.error("Something went wrong!");
          localStorage.clear();
          sessionStorage.clear();
        }
      } else {
        console.error("Something went wrong!");
      }
    }
  }

  // Helper function to get a new token
  async fetchToken() {
    try {
      localStorage.clear();
      sessionStorage.clear();

      const newToken = await login();
      if (newToken) {
        localStorage.setItem("token", newToken);
        this.token = newToken;
      }
    } catch (error) {
      console.error("Something went wrong!");
    }
  }
}

export default ApiRequest;
