import React from "react";
import { Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Input, Row } from "reactstrap";
import CountryOptions from "../../Home/SubSection/CountryOptions";
import JobType from "../../Home/SubSection/JobType";

const JobSearchOptions = ({
  searchTerm,
  setSearchTerm,
  onCountryChange,
  onJobTypeChange,
  onFilterClick,
  jobVacancyList,
}) => {
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="job-list-header">
        <Form action="#">
          <Row className="g-2">
            <Col lg={3} md={6}>
              <div className="filler-job-form">
                <i
                  className="uil uil-briefcase-alt"
                  style={{ margin: "-5px 0" }}
                ></i>
                <Input
                  type="search"
                  className="form-control filter-input-box"
                  id="exampleFormControlInput1"
                  placeholder="Job Title"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={{ marginTop: "-10px" }}
                />
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="filler-job-form">
                <i
                  className="uil uil-location-point"
                  style={{ margin: "-5px 0" }}
                ></i>
                <CountryOptions onCountryChange={onCountryChange} />
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="filler-job-form">
                <i
                  className="uil uil-clipboard-notes"
                  style={{ margin: "-5px 0" }}
                ></i>
                <JobType
                  jobVacancyList={jobVacancyList}
                  onJobTypeChange={onJobTypeChange}
                />
              </div>
            </Col>
            <Col lg={3} md={6}>
              <Link
                to="#"
                className="btn btn-primary w-100"
                onClick={onFilterClick}
              >
                <i className="uil uil-filter"></i> Filter
              </Link>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default JobSearchOptions;
