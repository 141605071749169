import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useRoutes from "./allRoutes";
import Error404 from "../pages/ExtraPages/Error404";
import ScrolltoTop from "../components/ScrolltoTop";
import ScrolltoTopPages from "../components/ScrolltoTopPages";

/* Layout */
const CommonLayout = React.lazy(() => import("../Layout/CommonLayout/index"));
const AuthLayout = React.lazy(() => import("../Layout/AuthLayout"));

const Loader = () => (
  <div id="preloader">
    <div id="status">
      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
);

const Index = () => {
  const { userRoutes, authRoutes } = useRoutes();

  return (
    <React.Fragment>
      <Suspense fallback={<Loader />}>
        <ScrolltoTopPages />
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />

          {/* Auth Routes */}
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<AuthLayout>{route.component}</AuthLayout>}
              key={idx}
            />
          ))}

          {/* User Routes */}
          {userRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<CommonLayout>{route.component}</CommonLayout>}
              key={idx}
            />
          ))}

          {/* Catch all unwanted routes for 404 */}
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Suspense>
    </React.Fragment>
  );
};

export default Index;
