import magicJson from "../magicJson/magicJson";

const login = async () => {

  //********************************USING TOKEN TO LOGIN ****************************/

  // const loginData = {
  //   email: process.env.REACT_APP_HCMS_USERNAME,
  //   password: process.env.REACT_APP_HCMS_PASSWORD,
  // };

  // magicJson.endpoint = "auth";
  // magicJson.executor = "login";
  // magicJson.data = [loginData];

  // const data = new FormData();
  // data.append("request", JSON.stringify(magicJson));
  // const requestOptions = {
  //   method: "POST",
  //   body: data,
  // };

  // try {
  //   localStorage.removeItem("token");

  //   const response = await fetch(process.env.REACT_APP_API_URL, requestOptions);
  //   const result = await response.json();
  //   const token = result.data[0].token;
  //   localStorage.setItem("token", token);
  //   return token;
  // } catch (error) {
  //   console.error("Something went wrong!");
  //   return null;
  // }
};

export default login;
